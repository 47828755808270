<template>
	<div class="index" v-loading.fullscreen.lock="fullscreenLoading" element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.8)">
		<cgHeader @select-type="handleSelectType" @search="handleSearch" />
		<div class="list">
			<div class="item" v-for="(item,index) in list" :key="index">
				<div v-if="item.vod_id" @click="toDetail(item.vod_id)">
					<img class="item-bg" :src="item.vod_pic" alt="" />
					<div class="item-content">
						<h2>
							{{item.vod_name}}
							<div class="wrap"><span class="wraps">热搜 HOT</span></div>
						</h2>
						<div class="post-card-info">
							<span itemprop="author" itemscope="" itemtype="http://schema.org/Person"></span>
							<span itemprop="datePublished" content="2024-03-28T04:00:00+00:00">{{item.vod_time}} &nbsp;•&nbsp;&nbsp;
							</span>
							<span>{{item.type_name}}</span>
						</div>
					</div>
				</div>
				<div v-else-if="item.ad_id" @click="openUrl(item.ad_url)">
					<img class="item-bg ad-img" :src="$baseURL+'/'+item.ad_logo" alt="" />
					<div class="item-content" style="background: rgba(0, 0, 0, 0.2);">
						<!-- <h2>
							{{item.vod_name}}
							<div class="wrap"><span class="wraps">热搜 HOT</span></div>
						</h2> -->
						<!-- <div class="post-card-info">
							<span itemprop="author" itemscope="" itemtype="http://schema.org/Person"></span>
							<span itemprop="datePublished" content="2024-03-28T04:00:00+00:00">{{item.vod_time}} • </span>
							<span>{{item.type_name}}</span>
						</div> -->
					</div>
				</div>
			</div>
			<el-pagination layout="prev, pager, next" :total="total" :page-size="20" @current-change="currentChange">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	import cgHeader from '@/components/cgHeader.vue'
	import {
		getData
	} from '@/api/index';

	export default {
		components: {
			cgHeader,
		},
		name: 'index',
		data() {
			return {
				list: [],
				t: 2,
				pg: 1,
				wd: '',
				total: 0,
				fullscreenLoading: false,
				fontSize: 10, //字体大小
				whei: 0, // 窗口宽度
			}
		},
		created() {
			this.init()
		},
		watch: {
			$route(to, from) {
				// 监听路由变化，重新处理参数
				this.handleQueryParams();
			}
		},
		mounted() {
			this.whei = window.innerWidth;
			this.setHtmlFontSize();
			// 监听窗口变化 
			window.addEventListener("resize", this.setHtmlFontSize);
		},
		methods: {
			/* 动态设置html字体大小 */
			setHtmlFontSize() {
				this.whei = window.innerWidth;
				document.querySelector("html").style.fontSize = this.whei / 20 + "px";
			},
			handleQueryParams() {
				// 获取路由参数
				const {
					tid,
					wd
				} = this.$route.query;
				if (tid !== undefined) {
					this.t = parseInt(tid); // 将 tid 转换为数字类型，根据需要进行处理
				}
				if (wd !== undefined) {
					this.wd = wd; // 设置关键字参数
					this.t = null
				}
				if (tid == undefined && wd == undefined) {
					this.t = 2
				}
				// 加载数据
				this.getList();
			},
			async init() {
				this.handleQueryParams();
				this.getList()
			},
			toDetail(id) {
				this.$router.push({
					path: '/detail',
					query: {
						id: id
					}
				})
			},
			openUrl(url) {
				window.open(url, '_blank');
			},
			handleSelectType(tid) {
				console.log(tid);
				// 处理来自 cgHeader 组件的 selectType 事件
				this.t = tid; // 更新类型参数
				this.wd = null
				this.getList(); // 根据新的类型刷新列表
			},
			handleSearch(keyword) {
				console.log(keyword); // 打印搜索关键字，确保正确获取到
				// 处理来自 cgHeader 组件的 search 事件
				this.wd = keyword; // 更新关键字参数
				this.t = null
				this.getList(); // 根据新的关键字刷新列表
			},
			currentChange(page) {
				this.pg = page
				this.getList()
			},
			getList() {
				this.list = []
				this.fullscreenLoading = true
				// 在获取数据时，可以根据 this.t 和 this.wd 来调整请求参数
				return new Promise((resolve, reject) => {
					getData({
							ac: 'list',
							t: this.t,
							pg: this.pg,
							wd: this.wd
						})
						.then(res => {
							this.list = res.list;
							this.total = res.total
							this.fullscreenLoading = false
							resolve();
						})
						.catch(err => {
							this.fullscreenLoading = false
							reject();
						});
				});
			}
		}
	}
</script>
<style scoped lang="scss">
	.index {
		background: #2c2a2a;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.list {
			width: 100%;
			margin-top: .8rem;
			
			@media screen and (max-width: 1199px) {
				margin-top: 1.2rem;
			}
			
			@media screen and (max-width: 799px) {
				margin-top: 2.8rem;
			}
		}

		.item {
			margin: 0 auto;
			position: relative;
			width: 10.32rem;
			height: 2.88rem;
			overflow: hidden;
			border-radius: .1rem;
			margin-bottom: .2rem;
			cursor: pointer;

			.item-bg {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				object-fit: cover;
			}

			.item-content {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: .2rem;
				box-sizing: border-box;
				background: rgba(0, 0, 0, .5);
				color: #fff;

				h2 {
					font-size: .28rem;
					font-weight: 400;
					margin-bottom: .2rem;
					text-align: center;

					.wraps {
						opacity: .6;
						display: inline-block;
						text-align: center;
						width: 2rem;
						height: .3rem;
						line-height: .27rem;
						position: absolute;
						top: .3rem;
						right: -0.5rem;
						z-index: 2;
						overflow: hidden;
						transform: rotate(45deg);
						-ms-transform: rotate(45deg);
						-moz-transform: rotate(45deg);
						-webkit-transform: rotate(45deg);
						-o-transform: rotate(45deg);
						border: 1px dashed;
						box-shadow: 0 0 0 1px #000, 0px 21px 5px -18px rgba(0, 0, 0, 0.6);
						background: rgb(1, 132, 127);
						font-size: .18rem;
					}
				}

				.post-card-info {
					font-size: .14rem;
					color: #fff;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			@media screen and (max-width: 1199px) {
				width: 15.88rem;
				height: 5rem;
				margin-bottom: .4rem;

				.item-content {

					h2 {
						font-size: .5rem;
						font-weight: 500;
						margin-bottom: .2rem;
						text-align: center;
						
						.wraps {
							width: 2.4rem;
							height: .5rem;
							top: .3rem;
							right: -0.5rem;
							font-size: .24rem;
							
							line-height: .48rem;
						}
					}
					
					.post-card-info {
						font-size: .24rem;
						color: #fff;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			@media screen and (max-width: 799px) {
				width: 90%;
				height: 10rem;
				margin-bottom: .6rem;
				border-radius: .4rem;
				
				.ad-img{
					object-fit: fill;
				}

				.item-content {

					h2 {
						font-size: 1rem;
						font-weight: 500;
						margin-bottom: .2rem;
						text-align: center;
						
						.wraps {
							width: 8rem;
							height: 1.5rem;
							top: 1rem;
							right: -2.3rem;
							font-size: .8rem;
							line-height: 1.6rem;
							text-align: center;
						}
					}
					
					.post-card-info {
						font-size: .6rem;
						color: #fff;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
	}
</style>